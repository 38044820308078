import 'url-search-params-polyfill';
/**
 * get meta object from meta tag fs-prop="analytics" on the main article (first article on the 3things)
 * @returns {object} Meta Object
 */
export function getPageMetadata () {
  const data = {};
  const metaEl = document.querySelector('[fs-prop="analytics"]');
  if (!metaEl) { return data; }
  const atts = metaEl.attributes;
  for (let i = 0, len = atts.length; i < len; i++) {
    if (atts[i].value != null) {
      let att = atts[i].name;
      const val = atts[i].value;
      att = (att.includes('-')) ? att.replace(/-/g, '_') : att;
      data[att] = val.trim();
    }
  }
  return data;
}

/**
 * generate unique id
 */
export function generateUUID () {
  const cryp = window.crypto || window.msCrypto;
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    (c ^ cryp.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
  );
}

/**
 * get unique device id
 */
export function getUUID () {
  const wis = window.wisRegistration;
  if (wis && typeof wis.getDeviceID === 'function' && wis.getDeviceID()) {
    return wis.getDeviceID();
  }
  // access local storage
  const storage = window.localStorage;
  let userJWT = storage.getItem('_WIS_anon_userJWT');
  if (userJWT) {
    userJWT = JSON.parse(userJWT);
    return userJWT.deviceId;
  }
  const userKey = 'fscom_user';
  let userData = storage.getItem(userKey);
  if (userData) {
    userData = JSON.parse(userData);
    return userData.deviceId;
  }

  // generate a unique
  const deviceId = generateUUID();
  userData = { deviceId };
  userData = JSON.stringify(userData);
  storage.setItem(userKey, userData);
  return deviceId;
}

function campaignParameterValueStorage () {
  const storage = window.sessionStorage || null;
  const prefix = 'segm_anl_';
  return {
    clear () {
      if (!storage) {
        return;
      }

      storage.clear();
    },
    getItem (key) {
      if (!storage) {
        return;
      }

      return storage.getItem(prefix + key);
    },
    setItem (key, value) {
      if (!storage) {
        return;
      }

      storage.setItem(prefix + key, value);
    }
  };
}

function extractCmpidQuery () {
  const queryStr = window.location.search;

  if (queryStr.length === 0) {
    return null;
  }

  const query = new URLSearchParams(queryStr);

  return query.get('cmpid') ? queryStr : null;
}

export function getCurrentCmpParameters () {
  const cmpidQ = campaignParameterValueExtract();
  const cmpResult = {
    cmpid: null,
    internal: null,
    isUpdated: false,
    query: window.location.search
  };

  if (!cmpidQ) {
    return cmpResult;
  }

  const query = new URLSearchParams(cmpidQ);
  const currQuery = new URLSearchParams(window.location.search || '');

  const cmpid = query.get('cmpid') || '';
  const isInternal = cmpid.includes('int_org');
  const isUpdated = cmpid === currQuery.get('cmpid');

  if (!isUpdated) {
    currQuery.append('cmpid', cmpid);
  }

  return {
    cmpid,
    internal: isInternal,
    isUpdated: cmpid === currQuery.get('cmpid'),
    query: currQuery.toString()
  };
}

function campaignParameterValueExtract () {
  const cmpidQ = extractCmpidQuery();
  const key = 'cmpIdQuery';
  const storage = campaignParameterValueStorage();

  if (!cmpidQ) {
    return storage.getItem(key);
  }

  storage.setItem(key, cmpidQ);

  return cmpidQ;
}

/**
 * Get Campaign Traits
 */
export function getCampaignTraits () {
  const cmpConfig = getCurrentCmpParameters();
  const docLocSrch = cmpConfig.query;
  // we found campaign params
  if (!docLocSrch) {
    return null;
  }

  const isInternal = cmpConfig.internal;
  const campaignTraits = getCampaignTraitsObject(isInternal);
  const result = {};
  // split campaign props
  const cmp = decodeURIComponent(cmpConfig.cmpid).replace(/::/g, '&');
  const subQuery = new URLSearchParams(cmp);
  for (const key of subQuery.keys()) {
    if (key in campaignTraits) {
      result[campaignTraits[key]] = subQuery.get(key);
    }
  }

  return result;
}

function getCampaignTraitsObject (isInternal) {
  const prefix = isInternal ? 'int_' : '';
  const campaignPrefix = `campaign_${prefix}`;

  return {
    [`${prefix}org`]: `${campaignPrefix}organization`,
    [`${prefix}ag`]: `${campaignPrefix}agency`,
    [`${prefix}mc`]: `${campaignPrefix}marketing_channel`,
    [`${prefix}src`]: `${campaignPrefix}source`,
    [`${prefix}cmp`]: `${campaignPrefix}name`,
    [`${prefix}add`]: `${campaignPrefix}additional`
  };
}

/*
`lastAnonymousProfileId`
 + populate when user hasn't logged in
 + pass blank on tracking when user is logged in
 + pass last anon id if user is logged out (?)

`lastKnownProfileId`
 + pass blank when user hasn't logged in
 + populate when user logs out
 + pass blank when user logs back in (?)
*/

function isObjectEmpty (obj) {
  for (const key in obj) {
    if (obj.hasOwnProperty(key)) { return false; }
  }
  return true;
}

export function getProfileData (objData = {}) {
  const wisReg = window.wisRegistration;
  if (wisReg) {
    const isLoggedIn = isUserLoggedIn();
    if (isLoggedIn) {
      const profileId = getUsrProfileId() || '';
      objData.dcg_profile_id = profileId || '';
      sessionStorage.setItem('lastKnownProfileId', profileId);
    }
    objData.lastKnownProfileId = sessionStorage.getItem('lastKnownProfileId') || '';
    const lastAnonProfileId = getAnonProfileId(wisReg) || sessionStorage.getItem('lastAnonymousProfileId');
    objData.lastAnonymousProfileId = lastAnonProfileId || '';
    sessionStorage.setItem('lastAnonymousProfileId', objData.lastAnonymousProfileId);
  }
  return objData;
}

export async function getJWT () {
  const wisReg = window.wisRegistration;
  if (wisReg) {
    return wisReg.getJWT();
  }
  return null;
}

function getUsrProfileId () {
  const wisReg = window.wisRegistration;
  if (typeof wisReg.getProfileId === 'function') {
    return wisReg.getProfileId();
  }
  return null;
}

function getAnonProfileId (wisReg) {
  if (typeof wisReg.getAnonProfileId === 'function') {
    return wisReg.getAnonProfileId();
  }
  return null;
}

function isUserLoggedIn () {
  const wisReg = window.wisRegistration;
  if (wisReg && typeof wisReg.isUserLoggedIn === 'function') {
    return wisReg.isUserLoggedIn();
  }
  return null;
}

function refreshState () {
  const wisReg = window.wisRegistration;
  if (wisReg && typeof wisReg.refreshState === 'function') {
    wisReg.refreshState();
  }
}

function getUserMetadata () {
  const wisReg = window.wisRegistration;
  if (wisReg && typeof wisReg.getUserMVPDMetadata === 'function') {
    const metadata = wisReg.getUserMVPDMetadata();
    if (metadata) {
      return metadata.item;
    }
  }
  return null;
}

export async function getAuthData () {
  refreshState();
  const profile = getProfileData();
  const mvpdMetadata = getUserMetadata();
  let userMetadata = {};
  if (mvpdMetadata) {
    userMetadata = {
      hbaStatus: mvpdMetadata.hbaStatus
    };
    if (window.fsSettings && window.fsSettings.featureflags && window.fsSettings.featureflags.enableUpstreamUserId) {
      userMetadata.upstreamUserID = mvpdMetadata.upstreamUserID
    }
  }
  const auth = {
    profile: {
      profileAnonymous: isProfileAnon(),
      profileId: profile.dcg_profile_id || null,
      lastKnownProfileId: profile.lastKnownProfileId,
      lastAnonymousProfileId: profile.lastAnonymousProfileId
    },
    authorizingNetwork: null,
    mvpdId: getMVPDId(),
    networkEntitlementList: await getUserEntitlementsList(),
    ...userMetadata
  };
  return auth;
}

export function isProfileAnon () {
  const isLoggedIn = isUserLoggedIn();
  if (isLoggedIn) {
    return !isLoggedIn;
  }
  return true;
}

/**
 * get geo location from session storage
 */
export function getGeoLocation () {
  // Get geolocation data from sessionStorage
  const geo = sessionStorage.getItem('fs_geolocation');
  if (geo) {
    return JSON.parse(geo);
  }
  return { geoLatitude: null, geoLongitude: null };
}

export async function getUserEntitlementsList () {
  const wisReg = window.wisRegistration;
  if (wisReg) {
    const entitlements = await wisReg.getUserEntitlements();
    if (!entitlements) { return null; }
    const channels = entitlements.channels.map(e => e.name);
    const ppvEvents = entitlements.ppvEvents.map(e => e.uri);
    let formmattedList = '';
    if (channels) {
      formmattedList = channels.toString();
    }
    if (ppvEvents && ppvEvents.length > 0) {
      const del = channels.length > 0 ? ',' : '';
      formmattedList += `${del}${ppvEvents.toString()}`;
    }
    return formmattedList;
  }
  return null;
}

/**
 * returns mvpd id
 */
export function getMVPDId () {
  const wisReg = window.wisRegistration;
  if (wisReg && typeof wisReg.getMVPDDetails === 'function') {
    const details = wisReg.getMVPDDetails();
    if (details) {
      return details.providerId;
    }
    return null;
  }
}

/**
 * get cookie by name
 * @param {string} name cookie name
 */
export const getCookie = (name) => {
  const foundCookie = document.cookie.split('; ').find(c => c.includes(`${name}=`)) || '';
  return foundCookie.split('=')[1] || null;
};

export const getFavoritesLists = (props) => {
  const wisReg = window.wisRegistration;
  if (wisReg && typeof wisReg.getFavoriteLists === 'function') {
    const lists = wisReg.getFavoriteLists();
    const types = ['sports', 'athletes', 'teams', 'content', 'personalities'];

    types.forEach((type) => {
      const propKey = `favorites_${type}_list`;
      const list = lists[propKey];
      if (list && list.length > 0) {
        props.properties[propKey] = list.toString();
      }
      props.properties[`favorites_${type}_length`] = list ? list.length : 0;
    });
  }
  return props;
};

export function getXID () {
  const xid = localStorage.getItem('xid');
  return xid;
}